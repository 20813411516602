// @ts-check
import HTMLParsedElement from "../html-parsed-element.js";

export const TAG_NAME = "gen-ad";

export class AdContainer extends HTMLParsedElement {

  constructor() {
    super();
    this.slotSize = { width: undefined, height: undefined };
    this.scalableFormats = [ "panorama", "panorama_top", "panorama_widget" ];
  }

  parsedCallback() {
    // Note: BAD currently does not support the resize event that the
    // old BAU script did, but with this generalized event handler we
    // can easily add it back in the future.
    this.addEventListener("bad:slotRenderEnded", this);
  }

  /**
   * Generalized event handler for the element.
   *
   * @param {Event} event
   */
  handleEvent(event) {
    // Remove prefix if present:
    const name = event.type.replace("bad:", "");
    const capName = name.charAt(0).toUpperCase() + name.slice(1);
    const method = `handle${capName}`;
    if (method in this) {
      /** @type {Object<string, any>} */(this)[method](event);
    } else {
      throw new Error(`No method named ${method} found on AdContainer`);
    }
  }

  /**
   * @param {CustomEvent} event
   */
  handleSlotRenderEnded(event) {
    this.setupResizing(event);
  }

  /**
   * @param {CustomEvent} event
   */
  setupResizing(event) {
    const slotName = this.getAttribute("data-slotname");
    if (!(this.scalableFormats.includes(slotName || "") && event.target instanceof HTMLElement)) {
      return;
    }
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const target = event.target;
    if (slotName === "panorama_widget") {
      this.style.setProperty("--ad-slot-transform-origin", "center");
    } else if (target.style.justifyContent === "center") {
      this.style.setProperty("--ad-slot-transform-origin", "top center");
    }
    const size = Array.isArray(event.detail.size) ? event.detail.size : [];
    const [ width, height ] = size;
    if (!(width && height)) {
      return;
    }
    this.slotSize = { width, height };
    this.style.setProperty("--ad-slot-width", `${width}px`);
    this.style.setProperty("--ad-slot-height", `${height}px`);
    if ("ResizeObserver" in window) {
      this.setupResizeObserver(event.target);
    } else {
      // Less efficient fallback for older browsers
      const desiredWidth = target.clientWidth;
      this.setResizedProperties(width, desiredWidth);
      // @ts-ignore since ResizeObserver is not always available, TS is wrong here.
      window.addEventListener("resize", () => {
        const newWidth = target.clientWidth;
        this.setResizedProperties(width, newWidth);
      });
    }
  }

  /**
   * Handles resizing of ad slot content, if the content is
   * (as per the slot data) larger than the ad wrapper element.
   *
   * @param {Element} slot
   */
  setupResizeObserver(slot) {
    // The element we will need to scale is the ad contents,
    // starting at the first child of the slot:
    const slotContent = slot.firstElementChild;

    // Check if slotContent exists (also helps with TS typing)
    if (!(slotContent && slotContent instanceof HTMLElement)) {
      return;
    }

    let skipFirstEntry = false;

    this.rObserver = new window.ResizeObserver((entries) => {
      entries.forEach((entry) => {
        // Skip the first entry after reconnecting, as it will trigger
        // an infinite loop due to the first observation will happen immediately:
        // (see flag toggled further down)
        if (skipFirstEntry) {
          skipFirstEntry = false;
          return;
        }

        // Figure out the container width/height, based on
        // resizeObserver entry - note that some older implementations (primarily Safari < 15.4)
        // did not have `borderBoxSize` arrays, but rather only the
        // contentRect API, so fall back to that.
        /** @type {{inlineSize?: number, blockSize?: number, width?: number, height?: number}} */
        const box = (
          (entry.borderBoxSize && entry.borderBoxSize.length)
            ? entry.borderBoxSize[0]
            : entry.contentRect);

        const containerWidth = box.inlineSize || box.width || Infinity;

        // If the slot size is smaller than the container, we dont want to rescale
        const desiredWidth = Math.min(this.slotSize.width || 0, containerWidth);

        const width = this.slotSize.width || 1;

        // Stop observing, for now - otherwise we risk an infinite loop
        this.rObserver.unobserve(this);

        this.setResizedProperties(width, desiredWidth);
        // Since reconnecting will trigger an initial resize observation
        // we risk an infinite loop - hence, we set a one-time flag to true here:
        skipFirstEntry = true;
        // Start observing again:
        this.rObserver.observe(this);
      });
    });
    this.rObserver.observe(this);
  }

  /**
   * @param {number} width
   * @param {number} desiredWidth
   */
  setResizedProperties(width, desiredWidth) {
    if (desiredWidth < width) {
      const scale = desiredWidth / width;
      this.style.setProperty("--ad-slot-scale", `${scale}`);
    } else {
      this.style.removeProperty("--ad-slot-scale");
    }
  }
}
