import {
  CUSTOM_PURPOSES,
  onConsentChanged,
  getUserConsentForPurpose,
  getUserConsentForVendor,
  onDidomiReady,
} from "@bonniernews/generika-didomi/main.js";

import { RichMediaAd } from "./rich-media-ad.js";

export async function handleFullpageAdConsent() {

  if (window.generika && window.generika.alwaysShowRichMediaAd) {
    return;
  }

  const adConsent = await getUserConsentForPurpose("select_basic_ads");
  const vendorConsent = await getUserConsentForVendor("bonniernews");
  const functionalAllowed = await getUserConsentForPurpose(CUSTOM_PURPOSES.functional);

  const enableFullpageAd = adConsent && vendorConsent && functionalAllowed;
  if (enableFullpageAd && !document.cookie.includes(RichMediaAd.cookieName)) {
    setRichMediaOkCookie();
  } else if (!enableFullpageAd && document.cookie.includes(RichMediaAd.cookieName)) {
    revokeRichMediaOkCookie();
  }
}

function setRichMediaOkCookie() {
  const date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 days
  document.cookie = `${RichMediaAd.cookieName}=true;expires=${date.toGMTString()}; path=/`;
}

function revokeRichMediaOkCookie() {
  document.cookie = `${RichMediaAd.cookieName}=true;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function setup() {
  onDidomiReady(() => {
    handleFullpageAdConsent();
    onConsentChanged(() => handleFullpageAdConsent());
  });
}
