/**
 *
 * @param {string} type
 * @param {Record<string, any>} payload
 */
export default function parsePayload(type, payload) {
  try {
    const data = JSON.parse(payload);
    return type in data && data[type];
  } catch (e) {
    return;
  }
}
